/* Tamaño titulos */

.h1-size-title {
    font-size: 62px;
    line-height: 60px;
}

.h2-size-title{
    font-size: 50px;
    line-height: 48px;
}

.h3-size-title{
    font-size: 40px;
    line-height: 45px;
}

.h4-size-title{
    font-size: 35px;
    line-height: 35px;
}

.h5-size-title{
    font-size: 25px;
    line-height: 25px;
}

.h6-size-title {
    font-size: 20px;
    line-height: 25px;
}

/* Tamaño parrafos */

.description-size {
    font-size: 27px;
    line-height: 34px;
}

.description-2-size {
    font-size: 24px;
    line-height: 28px;
}

.description-3-size {
    font-size: 20px;
    line-height: 22px;
}

.description-4-size {
    font-size: 18px;
    line-height: 21px;
}

.description-5-size {
    font-size: 16.5;
    line-height: 20px;
}

/*  Colores  */

.color-mcdt-primary{
    color: #111bff;
}

.color-mcdt-secondary{
    color:#1200E8
}

.color-mcdt-tertiary{
    color:#0f0a5c
}

.color-mcdt-quaternary{
    color: #15109f
}

.color-mcdt-quinary{
    color: #1306cd;
}
/* Fondos */

.bg-mcdt-primary{
    background-color: #111bff;
}


.bg-mcdt-secondary{
    background-color: #e8efff;
}

.bg-mcdt-tertiary{
    background-color: #d5e1ff;
}


/* Bordes */

.border-radius-top-mcdt{
    border-top-left-radius:25px;
    border-top-right-radius:25px
}

.border-radius-bottom-mcdt{
    border-bottom-left-radius:25px;
    border-bottom-right-radius:25px
}

/* Botones */
.btn-action-home{
    background-color: #06f339;
    border: 1px solid #06f339;
}

.btn-action-home:hover{
    background-color: #111bff;
    border: 1px solid #111bff;
}

.btn-action-secondary-home{
    background-color: #111bff;
    border: 1px solid #111bff;
}

.btn-action-secondary-home:hover{
    background-color: #06f339;
    border: 1px solid #06f339;

}

.btn-action-home,
.btn-action-secondary-home{
    transition: all .5s;
    border-radius: 30px;
    font-size: 25px;
    color: white;
    font-weight: bold;
    padding: 7px 20px;
    cursor: pointer;
    text-shadow: 1px 1px 1px #00000033;
}

.btn-action-home:hover,
.btn-action-secondary-home:hover{
    transition: all .5s;
    transform: scale(1.02);
    box-shadow: 3px -2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

/* Margenes */

.m-top-home{
    margin-top: 40px;
}

.display-none{
    display: none;
}


@media (max-width: 991px) {
    .h1-size-title {
        font-size: 45px;
        line-height: 40px;
    }

    .description-size {
        font-size: 22px;
        line-height: 24px;
    }

    .h2-size-title {
        font-size: 40px;
        line-height: 40px;
    }

    .description-2-size {
        font-size: 22px;
        line-height: 25px;
    }

    .h3-size-title {
        font-size: 30px;
        line-height: 34px;
    }

    .h4-size-title{
        font-size: 26px;
        line-height: 27px;
    }

    .h5-size-title {
        font-size: 21px;
        line-height: 20px;
    }

    .h6-size-title {
        font-size: 18px;
        line-height: 23px;
    }

    .description-5-size {
        font-size: 16.7px;
        line-height: 19px;
    }    
}

@media (max-width: 768px) {
    .h1-size-title {
        font-size: 32px;
        line-height: 28px;
    }

    .description-size {
        font-size: 18px;
        line-height: 20px;    
    }


    .btn-action-home {
        font-size: 18px;
    }

    .h2-size-title {
        font-size: 25px;
        line-height: 25px;
    }

    .description-2-size {
        font-size: 17px;
        line-height: 20px;
    }

    .h3-size-title {
        font-size: 22px;
        line-height: 25px;
    }

    .h4-size-title{
        font-size: 19px;
        line-height: 19px;
    }

    .h5-size-title {
        font-size: 17px;
        line-height: 18px;
    }

    .h6-size-title {
        font-size: 15.5px;
        line-height: 23px;
    }

    .description-3-size {
        font-size: 18px;
        line-height: 22px;
    }

    .description-4-size {
        font-size: 16px;
        line-height: 20px;
    }

    .description-5-size {
        font-size: 14.7px;
        line-height: 18px;
    }

    
}

@media (max-width: 575px) {
    .h1-size-title {
        font-size: 40px;
        line-height: 38.5px;
    }

    .description-size {
        font-size: 23px;
        line-height: 30px;
    }

    .h2-size-title {
        font-size: 35px;
        line-height: 34px;
    }

    .description-2-size {
        font-size: 22px;
        line-height: 24px;
    }

    .h3-size-title {
        font-size: 30px;
        line-height: 35px;
    }

    .h4-size-title{
        font-size: 25px;
        line-height: 25px;
    }

    .h5-size-title {
        font-size: 22px;
        line-height: 22px;
    }

    .h6-size-title {
        font-size: 14.5px;
        line-height: 19px;
    }

    .description-4-size {
        font-size: 14px;
        line-height: 19px;
    }

    .description-5-size {
        font-size: 17.7px;
        line-height: 24px;
    }

    .m-top-home{
        margin-top: 25px;
    }

    .btn-action-home,
    .btn-action-secondary-home {
        font-size: 20px;
        width: 100%;
        padding: 8px 0px;
        border-radius: 15px;
    }

}

@media (max-width: 420px) {
    .h1-size-title {
        font-size: 30px;
        line-height: 29.5px;
    }

    .description-size {
        font-size: 20px;
        line-height: 23px;
    }

    .h2-size-title {
        font-size: 27px;
        line-height: 28px;
    }

    .description-2-size {
        font-size: 18px;
        line-height: 21px;
    }

    .h3-size-title {
        font-size: 25px;
        line-height: 26px;
    }

    .description-3-size {
        font-size: 16.5px;
        line-height: 22px;
    }

    .h4-size-title{
        font-size: 21.5px;
        line-height: 23px;
    }

    .h5-size-title {
        font-size: 20.5px;
        line-height: normal;
    }

    .description-5-size {
        font-size: 16px;
        line-height: 21px;
    }

    .m-top-home{
        margin-top: 20px;
    }

    .btn-action-home,
    .btn-action-secondary-home {
        font-size: 17.5px;
        border-radius: 30px;
    }
}

/* Fin Tamaño titulos */