.brand_flamingo 
  --color-white: #FFFFFF
  --color-blue-10: #cf080f08
  --color-blue-20: #fff4f4
  --color-blue-40: #fcd5d6
  --color-blue-60: #ffa8ab
  --color-blue-100: #cf080f 
  --color-blue-150: #a00207
  --color-blue-180: #750205
  --color-black-10: #D3D3D3
  --color-black-50: #898989
  --color-black-100: #363636
  --color-red: #FF5050
  --color-green: #5AC62D
  --color-aquamarine: #00C2FF
  --color-yellow: #FFD600
