.vip-activate


  ._hoy-los-bancos-ofrecen,
  ._validar-identidad,
  ._preguntas-ban100,
  ._preguntas-bancamia,
  ._preguntas-bancow,
  ._preguntas-bancomundomujer,
  ._preguntas-mibanco,
  ._preguntas-coltefinanciera,
  ._preguntas-financieradannregional,
  ._preguntas-credifamilia,
  ._confirma-tus-datos,
  ._bienvenida-usuario-retorno,
  ._datos-personales,
  ._datos-financieros,
  ._sube-tus-documentos,
  ._asegura-tu-tasa,
  ._transfiere-el-dinero,
  ._cdt-completado,
  ._firma-los-documentos-en-fisico

    .vip-tag, .tag-vip-text
        display: block !important
        background: linear-gradient(90deg, rgba(255, 222, 116, 1) 25%, rgba(255, 212, 85, 1) 40%, rgba(255, 255, 255, 1) 50%, rgba(255, 212, 85, 1) 60%, rgba(255, 195, 22, 1) 75%)
        transition: background-position 1.5s ease-in-out
        animation: brillo 2.5s infinite
        background-size: 200% 100%
        background-position: 0% 0%
        font-weight: bold
        border: 0px
        margin-top: -5px

    .footer-page
        background-color: black !important

    .footer-page .text-white
        color: #ffc000 !important

    .div-logo-mobile .tooltip-inner
      background: black
      opacity: 1!important
      color: white
      box-shadow: 0px 0px 8px 1px #00000038
      width: 200px!important
      padding: 5px 8px
      line-height: normal
      text-align: center
      font-size: 13px

    .step-indicator.blue-container-10
      background-color: var(--color-white)
      border-color: var(--color-black-10)

    .text-green
      color: #ffc000

    .text-blue-100
      color: #000

    .bgSombreadoTema
      background: white !important




  ._hoy-los-bancos-ofrecen
    .btn:hover
        filter: brightness(1) !important

    .banner-promo img, .banner-promo-confirm, .width-all-process img
        filter: grayscale(1)

    #InfoBanksCDTS .tag-vip-text
        padding: 0px 9px
        border-radius: 25px
        font-size: 18px
        display: inline-block !important

    .bgSombreadoTema
        background: white !important

    #main_nav .navbar-nav
        .nav-item.dropdown .dropDow-menu-opt
            background: white !important

        .nav-link img, .nav-item.dropdown .dropDow-menu-opt img
            filter: grayscale(1) !important

    .items-aperture
        .test-info-banks .target-promo
            box-shadow: none !important

        .shadow-cdts
            border: 1px solid #d1d1d1

    .returning-user, .btn-open-configuration, .target-promo, .navigation
        background-color: #000000 !important
        color: #ffffff !important
        border: 2px solid #000000 !important

    .returning-user button
        border: 1px solid white !important
        color: white !important

    #InfoBanksCDTS
        .name_MejorCDT_terminado, .text_MejorCDT_terminado, .icon_MejorCDT_terminado
            color: #858585 !important

        .name_MejorCDT_terminado, .text_MejorCDT_terminado, .icon_MejorCDT_terminado
            color: #909090 !important

    .toltip_MejorCDT_actual::after
        border-color: #ffc000 transparent transparent transparent !important

    .footer .section-1 .row:first-child,
    .footer .section-2 .row:first-child
        border-bottom: 1px solid #ffc000 !important

    .footer .section-3
        background-color: #2d2d2c!important

    #box-better-profitability, .item-tag-neg, .carousel-indicators button
        background-color: black !important

    #filters input[type=radio]:checked +
      .generalTags, .riskTags
        background-color: black !important

    .opt-login-header
      background-color: black !important

    #box-better-profitability, .text-black,
    #box-better-profitability, .title-results-simulate, 
    .tasas-expresadas-anual,
    #box-better-profitability, .vip-text
      color: white !important

    #InfoBanksCDTS
      .text_MejorCDT_actual, .icon_MejorCDT_actual, .name_MejorCDT_actual
        color: black !important

    #main_nav .nav-item a, .fullname-user-login, .icon-user-login i, .show-information, .info-banner h5, .carousel-item .description
      color: black !important


    .carousel-item .title, .color-mcdt
      color: black !important

    .options_CheckParametros_ModalMobile
      .regresar-listado, #amount
        color: black !important

    .color-mcdt-primary, .navigation li.active a .icon, ul#config-login-header li span.dropdown-item, #InfoBanksCDTS .tag-vip-text
      color: black !important

    .returning-user .close-alert
      span, i
        color: #ffffff !important

    #filters
      input[type=radio]:checked +
        .generalTags, .riskTags
          color: #ffffff !important

      .generalTags:hover, .riskTags:hover
        color: #ffffff !important

    .carousel-item .card .btn-outline-primary:hover, #main_nav .li-opt-login span, .width-all-process .btn-process-all:hover
      color: #ffffff !important

    #box-better-profitability form h3,
    .options_CheckParametros_ModalMobile .colorAmount,
    .returning-user .ver-mas,
    .entry-field-icon,
    .li-tag-vip
      color: #ffc000 !important

    #box-better-profitability
      .montoInicial, .montoFinal
        background-color: #ffffff !important
        color: black !important

    .btn-edit
      background: #dddddd !important
      color: #000000 !important

    .mtop-recalculate
      color: #ffc000 !important
      -webkit-text-stroke: 1px #ffc000 !important

    #box-better-profitability .btn-opencdt,
    .items-aperture .btn-opencdt button,
    #relevants-cards .btn-primary,
    .options_CheckParametros_ModalMobile #botonEnviarDatos,
    #login-modal #botonEnviarDatos,
    .width-all-process .bnt-process-reciente,
    .toltip_MejorCDT_actual,
    .tag-term-selected,
    .change-investment-mobile,
    .navigation .indicator,
    .progress
      font-weight: bold
      border: 0px solid #ffc000 !important
      background: linear-gradient(90deg, rgb(255 222 116) 33%, rgb(255 212 85) 49%, rgb(255 195 22) 79%) !important
      color: black !important
      transition: all .5s

    .returning-user
      button:hover, .close-alert:hover i:before
        font-weight: bold
        border: 0px solid #ffc000 !important
        background: linear-gradient(90deg, rgb(255 222 116) 33%, rgb(255 212 85) 49%, rgb(255 195 22) 79%) !important
        color: black !important
        transition: all .5s

    .calification, .btn.btn-card-filter
      background: transparent !important
      border: 1px solid
      color: black !important

    .item-tag
      color: #000000 !important
      background-color: #e3e3e3 !important

    .carousel-item .card
      border-radius: 20px !important
      border: 3px solid #000000 !important

      .btn-outline-primary
        color: black !important
        border: 1px solid black !important

    #relevants-cards .btn-outline-primary
      color: black !important
      border: 1px solid black !important

    #filters
      .generalTags, .riskTags
        color: black !important
        border: 1px solid black !important

    .width-all-process .btn-process-all, #main_nav .navbar-nav .nav-item.dropdown .dropDow-menu-opt
      color: black !important
      border: 1px solid black !important

    #relevants-cards .card, .width-all-process .steep_process
      background-color: black !important
      border: 1px solid black !important
      color: white !important

    .faq .question
      color: black !important
      background: #e8e8e8 !important

@keyframes brillo
  0%
    background-position: 0% 0%

  50%
    background-position: 100% 0%

  100%
    background-position: 0% 0%

.boton-brillo
  animation: brillo 1.5s infinite
