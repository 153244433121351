.brand_mcdt {
  --color-white: #FFFFFF;
  --color-blue-10: #F1F1FF;
  --color-blue-20: #E2E3FF;
  --color-blue-40: #C4C6FF;
  --color-blue-60: #6B71FF;
  --color-blue-100: #111BFF;
  --color-blue-150: #090E80;
  --color-blue-180: #070B60;
  --color-black-5: #e9e9e9;
  --color-black-10: #D3D3D3;
  --color-black-50: #898989;
  --color-black-100: #363636;
  --color-red: #FF5050;
  --color-green: #5AC62D;
  --color-aquamarine: #00C2FF;
  --color-yellow: #FFD600;
  /*Colors Brand Turbo*/
  --color-turbo-blue: #81d3ff;
  --color-turbo-green-60: #CDEEC0;
  --color-turbo-green-100: #03CB6A;
  --color-turbo-green-150: #006F39;
  --color-turbo-green-180: #02572E;
}

/* Widths / Dimensiones Botones */

.btn-width-100 {
  width: 240px !important;
}

.btn-width-150{
  width: 350px !important;
}
/*BACKGROUND, BG HOVER*/
.bg-blue-10, .bg-hover-blue-10:hover  {background-color: var(--color-blue-10)!important}
.bg-blue-20, .bg-hover-blue-20:hover  {background-color: var(--color-blue-20)!important}
.bg-blue-40, .bg-hover-blue-40:hover  {background-color: var(--color-blue-40)!important}
.bg-blue-60, .bg-hover-blue-60:hover  {background-color: var(--color-blue-60)!important}
.bg-blue-100, .bg-hover-blue-100:hover  {background-color: var(--color-blue-100)!important}
.bg-blue-150, .bg-hover-blue-150:hover  {background-color: var(--color-blue-150)!important}
.bg-blue-180, .bg-hover-blue-180:hover  {background-color: var(--color-blue-180)!important}
.bg-yellow, .bg-hover-yellow:hover  {background-color: var(--color-yellow)!important}
.bg-white, .bg-hover-white:hover  {background-color: var(--color-white)!important}
.bg-green, .bg-hover-green:hover {background-color: var(--color-green)!important}
.bg-black-5, .bg-hover-black-5:hover  {background-color: var(--color-black-5)!important}
.bg-black-10, .bg-hover-black-10:hover {background-color: var(--color-black-10)!important}
.bg-black-50, .bg-hover-black-50:hover  {background-color: var(--color-black-50)!important}
.bg-black-100, .bg-hover-black-100:hover  {background-color: var(--color-black-100)!important}
/*Bg Brand Turbo*/
.bg-turbo-green-60 {background-color: var(--color-turbo-green-60)!important}
.bg-turbo-green-100 {background-color: var(--color-turbo-green-100)!important}
.bg-turbo-green-150 {background-color: var(--color-turbo-green-150)!important}
.bg-turbo-green-180 {background-color: var(--color-turbo-green-180)!important}
.bg-turbo-green-gradient-100 { background: -webkit-linear-gradient(90deg, var(--color-turbo-blue),var(--color-turbo-green-100));background: linear-gradient(90deg, var(--color-turbo-blue),var(--color-turbo-green-100))}
.bg-turbo-green-gradient-150 { background: -webkit-linear-gradient(90deg, var(--color-turbo-green-100),var(--color-turbo-green-150));background: linear-gradient(90deg, var(--color-turbo-green-100),var(--color-turbo-green-150))}

/*TEXTS*/
/*Colors Blues and Hover*/
.text-turbo-100 {color: var(--color-turbo-green-100)!important}
.text-blue-10, .text-hover-blue-10:hover {color: var(--color-blue-10)!important}
.text-blue-20, .text-hover-blue-20:hover {color: var(--color-blue-20)!important}
.text-blue-40, .text-hover-blue-40:hover {color: var(--color-blue-40)!important}
.text-blue-60, .text-hover-blue-60:hover {color: var(--color-blue-60)!important}
.text-blue-100, .text-hover-blue-100:hover {color: var(--color-blue-100)!important}
.text-blue-150, .text-hover-blue-150:hover {color: var(--color-blue-150)!important}
.text-blue-180, .text-hover-blue-180:hover {color: var(--color-blue-180)!important}
/*Colors Black*/
.text-black-10, .text-hover-black-10:hover {color: var(--color-black-10)!important}
.text-black-50, .text-hover-black-50:hover {color: var(--color-black-50)!important}
.text-black-100, .text-hover-black-100:hover {color: var(--color-black-100)!important}
/*Color White*/
.text-white, .text-hover-white:hover {color: var(--color-white)!important}
/*Color Green*/
.text-green, .text-hover-green:hover {color: var(--color-green) !important}
/*Titles size*/
.title-1, .title-2, .title-3, .title-4, .title-5, .title-6 {font-weight: 700 !important}
.title-1 {font-size: 52px !important}
.title-2 {font-size: 40px !important}
.title-3 {font-size: 36px !important}
.title-4 {font-size: 32px !important}
.title-5 {font-size: 24px !important}
.title-6 {font-size: 20px !important}
/*Body sizes*/
.text-size-xl {font-size: 30px !important}
.text-size-lg {font-size: 24px !important}
.text-size-md {font-size: 18px !important}
.text-size-nl {font-size: 16px !important}
.text-size-sm {font-size: 12px !important}
.text-size-ty {font-size: 9px !important}
/* Bordes */
.border-blue-10 {border-color: var(--color-blue-10)!important}
.border-blue-20 {border-color: var(--color-blue-20)!important}
.border-blue-40 {border-color: var(--color-blue-40)!important}
.border-blue-60 {border-color: var(--color-blue-60)!important}
.border-blue-100 {border-color: var(--color-blue-100)!important}
.border-blue-150 {border-color: var(--color-blue-150)!important}
.border-blue-180 {border-color: var(--color-blue-180)!important}

@media (max-width: 768px) {
  /* Width / Dimesiones Botones */
  .btn-width-100,
  .btn-width-150 {
    width: 100% !important;  
  }

  /*Titles size*/
  .title-1 {font-size: 40px !important}
  .title-2 {font-size: 36px !important}
  .title-3 {font-size: 32px !important}
  .title-4 {font-size: 24px !important}
  .title-5 {font-size: 20px !important}
  .title-6 {font-size: 16px !important}
  /*Body sizes*/
  .text-size-xl {font-size: 24px !important}
  .text-size-lg {font-size: 20px !important}
}
/*Styles*/
.font-bold {font-weight: bold !important}
.font-semibold {font-weight: 600 !important}
.font-regular {font-weight: normal !important}
.font-light {font-weight: 300 !important}
.font-underlined {text-decoration: underline !important}

.text-shadow-blue {text-shadow: 1px 1px 4px var(--color-blue-150) !important}

/*CONTAINERS*/
.white-container-shadow, .white-container, .blue-container-10, .blue-container-20 {
  border-radius: 5px;
  border: solid 1px;
}
/*White containers*/
.white-container {
  border-color: var(--color-black-10);
  background-color: var(--color-white);
}
.white-container-shadow {
  border-color: var(--color-white);
  background-color: var(--color-white);
  box-shadow: 0 2px 15px 0 var(--color-black-10);
}
/*Blue containers*/
.blue-container-10 {
  border-color: var(--color-blue-20);
  background-color: var(--color-blue-10);
}
.blue-container-20 {
  border-color: var(--color-blue-60);
  background-color: var(--color-blue-20);
}

/*ELEMENTS*/
/*Buttons*/
.btn {transition: filter 0.3s ease !important;}
.btn-lg {font-weight: 600 !important; padding-left: 1.5rem !important; padding-right: 1.5rem !important;}
.btn:hover {filter: brightness(0.9)}
.btn.button:disabled,.btn[disabled],.btn-disabled {
  background-color: var(--color-black-50) !important;
  border-color: var(--color-black-50) !important;
  color: var(--color-white) !important;
  cursor: not-allowed !important;
}
.btn > i {margin: 5pt !important}
.btn-primary-green, .btn-primary-blue, .btn-primary-red {color: var(--color-white) !important}
.btn-primary-green {background-color: var(--color-green) !important}
.btn-primary-blue {background-color: var(--color-blue-100) !important}
.btn-primary-red {background-color: var(--color-red) !important}
.btn-outline-primary-green, .btn-outline-primary-blue, .btn-outline-primary-red {background-color: var(--color-blue-10) !important}
.btn-outline-primary-green {
  background-color: var(--color-white) !important;
  color: var(--color-green) !important;
  border-color: var(--color-green) !important;
}
.btn-outline-primary-blue {
  background-color: var(--color-white) !important;
  color: var(--color-blue-100) !important;
  border-color: var(--color-blue-100) !important;
}
.btn-outline-primary-red {
  background-color: var(--color-white) !important;
  color: var(--color-red) !important;
  border-color: var(--color-red) !important;
}
/*Buttons Brand Turbo*/
.btn-turbo-primary-green, .btn-turbo-primary-green-180 {color: var(--color-white) !important}
.btn-turbo-primary-green {background-color: var(--color-turbo-green-100) !important}
.btn-turbo-outline-primary-green {
  background-color: var(--color-white) !important;
  color: var(--color-turbo-green-100) !important;
  border-color: var(--color-turbo-green-100) !important;
}
.btn-turbo-primary-green-180 {background-color: var(--color-turbo-green-180) !important}
.btn-turbo-outline-primary-green-180 {
  background-color: var(--color-white) !important;
  color: var(--color-turbo-green-180) !important;
  border-color: var(--color-turbo-green-180) !important;
}
/*Separator*/
hr {border: 1px solid var(--color-blue-40) !important}


/* Animation clases */
.animation-brillo-green{
  background-size: 200% 100%;
  animation: brillo 4s linear infinite;
  font-weight: bold !important;
  color: white;
  background-image: radial-gradient(circle at 50.37% 50%, #ffffff 0%, #5ac62d 20%, #6ad13d 100%) !important;
}

/* keyframes animations */

@keyframes brillo {
  0% {
      background-position: 0% 0%
  }


  50% {
      background-position: 100% 0%
  }

  100% {
      background-position: 0% 0%
  }

}